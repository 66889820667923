<template>
    <div class="dashboard">

        <div class="dash-wrap"> 

            <div class="sidebar">
                <div class="filter">
                    <div class="wrap">
                        <div class="item">
                            <vs-select label="Año" v-model="year">
                                <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in yearOptions" />
                            </vs-select>
                        </div>
                        <div class="item">
                            <vs-select label="Mes" v-model="month">
                                <vs-select-item :key="index" :value="index" :text="item" v-for="(item,index) in monthOptions" />
                            </vs-select>
                        </div>
                    </div>
                    <div class="item">
                        <vs-select label="Filtro Fecha" v-model=dateFilter>
                            <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in dateTypes" />
                        </vs-select>
                    </div>
                    <div class="item">
                        <vs-button color="primary" type="filled" @click="loadingCharts()">Filtrar</vs-button>
                    </div>
                </div>
                <div class="counter">
                    <div class="item">
                        <span>{{ totalPax }}</span>
                        <p>viajeros</p>
                    </div>
                    <div class="item">
                        <span>{{ $n(totalCost, 'currency') }}</span>
                        <p>Costo</p>
                    </div>
                    <div class="item">
                        <span>{{ totalMargin }}%</span>
                        <p>Margin</p>
                    </div>
                </div>
            </div>
            
            <div class="content">
                <div class="widget d-flex">
                    <div class="counter">
                        <div class="item">
                            <span>{{ $n(totalInvoiced, 'currency') }}</span>
                            <p>Invoice</p>
                        </div>
                        <div class="item">
                            <span>{{ $n(totalReceived, 'currency') }}</span>
                            <p>Recived</p>
                        </div>
                    </div>
                    <div class="agencies"></div>
                </div>
                <div class="widget">
                    <div class="subtitle">Venta Directa vs Indirecta</div>
                    <bar-chart :key="key" :dataChart="statsValuesDirectIndirect" :labels="statsLabelsDirectIndirect" 
                        :height="200" axis="y"></bar-chart>
                </div>
                <div class="widget">
                    <div class="subtitle">Cant Pax | Anual</div>
                    <line-chart :key="key" :labels="statsPaxMonths.labels" 
                        :options="statsPaxMonths.values" :height="250"></line-chart>
                </div>
                <div class="widget">
                    <div class="subtitle">Cost vs Retail | Anual</div>
                    <bar-chart :key="key" :labels="statsCostRetail.labels" :dataChart="statsCostRetail.values" 
                        :height="250" title="s"></bar-chart>
                </div>
                <div class="widget gap-2">
                    <div class="subtitle">Invoiced vs Received | Agencias</div>
                    <bar-chart :key="key" :labels="statsRecInv.labels" :dataChart="statsRecInv.values" 
                        :height="350" title="s"></bar-chart>
                </div>
            </div>
        </div>  

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
// import CatalogService from '@/services/CatalogService'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')


import lineChart  from '@/components/Charts/Line'
import barChart  from '@/components/Charts/Bar'

export default {
    name: "BookingsView",
    components: {
        modal,
        lineChart,
        barChart,
    },
    data () {
        return {
            switch1: "",
            key: 1,
            date: "",
            reference: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            totalPax    : 0,
            totalCost   : 0,
            totalRetail : 0,
            totalMargin : 0,
            paxMonth: [],
            statsPaxMonths: {
                labels: [],
                values: []
            },
            monthAbr: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            costRetailData: [],
            statsCostRetail: {
                labels: [],
                values: []
            },
            salesDirectIndirect: [],
            statsDirectIndirect: [],
            statsValuesDirectIndirect: [],
            statsLabelsDirectIndirect: [],

            totalInvoiced: 0,
            totalReceived: 0,
            statsInvRec: [],
            statsRecInv: {
                labels: [],
                values: []
            },
            month: 0,
            year: new Date().getFullYear(),
            yearOptions: [],
            monthOptions: ['Todos','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre',],

            dateFilter: "travelDate",
            dateTypes: [{ code: "travelDate", name:"Travel Date"}, {code: "sellDate", name: "Sell Date"}]
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        
        for (let i = this.year; i > this.year - 10; i--) {
            this.yearOptions.push(i);
        }

        this.loadingCharts()

         
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async loadingCharts() {
            this.LOADING_STATUS(true);
            this.paxMonth = [],
            this.statsPaxMonths = {
                labels: [],
                values: []
            };
            this.costRetailData= [];
            this.statsCostRetail= {
                labels: [],
                values: []
            };
            this.salesDirectIndirect= [];
            this.statsDirectIndirect= [];
            this.statsValuesDirectIndirect= [];
            this.statsLabelsDirectIndirect= [];
            this.statsInvRec= [];
            this.statsRecInv= {
                labels: [],
                values: []
            };
            await this.getQtyPax();
            await this.getTotalCostSales();
            await this.getTotalMarginSales();
            await this.getPaxPerYear();
            await this.getCostVsRetail();
            await this.getSalesDirecVsIndirect();
            await this.getSalesRecVsInv();
            await this.getSalesRecVsInvByAgencies();
            this.LOADING_STATUS(false);
        },
        async getSalesRecVsInvByAgencies(){            
            let response = await BookingService.getSalesRecVsInvByAgencies({
                "year": this.year,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.statsInvRec = response.data.data;
                let arrInvoiced = [];
                let arrReceived = [];
                this.statsInvRec.forEach( item => {
                    this.statsRecInv.labels.push(item.AGENT); 
                    arrInvoiced.push(item.ValueTotalInvoiced);
                    arrReceived.push(item.ValueTotalReceived);
                });
                this.statsRecInv.values.push({
                    label: 'Received',
                    backgroundColors: ['#005dff'],
                    data: arrReceived
                });
                this.statsRecInv.values.push({
                    label: 'Invoiced',
                    backgroundColors: ['#b8d2ff'],
                    data: arrInvoiced
                });
            } 
            this.LOADING_STATUS(false);
        },
        async getSalesRecVsInv(){            
            let response = await BookingService.getSalesRecVsInv({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.totalInvoiced = response.data.data.ValueTotalInvoiced;
                this.totalReceived = response.data.data.ValueTotalReceived;
            } 
        },
        async getQtyPax(){            
            let response = await BookingService.getQtyPax({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.totalPax = response.data.quatity
            } 
        },
        async getTotalCostSales(){            
            let response = await BookingService.getTotalCostSales({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.totalCost = response.data.costAmount
            } 
        },
        async getTotalMarginSales(){            
            let response = await BookingService.getTotalMarginSales({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.totalMargin = response.data.margin
            } 
        },
        async getSalesDirecVsIndirect(){            
            let response = await BookingService.salesDirecVsIndirect({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.salesDirectIndirect = response.data.data
                this.salesDirectIndirect.forEach( month => {
                    this.statsValuesDirectIndirect.push(month.AMOUNT)
                    this.statsLabelsDirectIndirect.push(month.SALE)
                });
                this.statsValuesDirectIndirect = [{ data: this.statsValuesDirectIndirect }]
            } 
        },
        async getCostVsRetail(){            
            let response = await BookingService.getCostVsRetail({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.costRetailData = response.data.data
                let arrCost = [];
                let arrRetail = [];
                this.costRetailData.forEach( month => {
                    let d = new Date(month.MONTH);
                    this.statsCostRetail.labels.push(this.monthAbr[d.getMonth()]); 
                    arrCost.push(month.COST);
                    arrRetail.push(month.RETAIL);
                });
                this.statsCostRetail.values.push({
                    label: 'Cost',
                    backgroundColors: ['#b8d2ff'],
                    data: arrCost
                });
                this.statsCostRetail.values.push({
                    label: 'Retail',
                    backgroundColors: ['#005dff'],
                    data: arrRetail
                });
            } 
        },
        async getPaxPerYear(){            
            let response = await BookingService.getPaxPerYear({
                "year": this.year,
                dateFilter: this.dateFilter,
                "month": this.month == 0 ? "" : this.month,
            });            
            if((response.status/100) == 2){
                this.paxMonth = response.data.data
                this.paxMonth.forEach( month => {
                    let d = new Date(month.MONTH);
                    this.statsPaxMonths.labels.push(this.monthAbr[d.getMonth()]); 
                    this.statsPaxMonths.values.push(month.CUSTOMERS_QTY);
                });
            } 
        },
        // async getSales(){            
        //     this.LOADING_STATUS(true);
        //     let response = await BookingService.getSales({
        //         "begDate" : this.date != '' ? this.date.start : "", //opcional
        //         "endDate" : this.date != '' ? this.date.end : "",//opcional
        //         "reference" : this.reference, //opcional
        //         "agencies" :    this.agencies, //opcional
        //         "consultants" : this.vendors, //opcional
        //         "booking_status": this.stBookings,
        //         "typeDate": this.typeDate
        //     });            
        //     if((response.status/100) == 2){
        //         this.sales = response.data
        //     } 
        //     let marginCounter = 0;
        //     this.totalPax    = 0;
        //     this.totalCost   = 0;
        //     this.totalPorcRetail = 0;
        //     this.totalRetailReal = 0;
        //     this.totalRetail = 0;
        //     this.totalMargin = 0;
        //     this.sales.forEach(sale => {
        //         this.totalPax    = this.totalPax  + sale.PAX;
        //         this.totalCost   = this.totalCost + sale.COST;
        //         this.totalPorcRetail = this.totalPorcRetail + sale.PORC_RETAIL,
        //         this.totalRetailReal = this.totalRetailReal + sale.RETAILREAL,
        //         this.totalRetail = this.totalRetail  + sale.RETAIL;
        //         this.totalMargin = this.totalMargin  + sale.MARGIN; 
        //         marginCounter++
        //     });           
        //     this.totalMargin = this.totalMargin / marginCounter;
        //     this.LOADING_STATUS(false);
        // },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>